import React from 'react'
import loadable from 'loadable-components';
import Loader from '../components/presentationals/Loader/Loader.component'
// website
export const NotexistPage = React.lazy(() => import('./pagenotexist'));

export const LoginContainer = React.lazy(() => import('../components/containers/Admin/Login.container'));

export const App = React.lazy(() => import('./App.Layout'))

export const Home = React.lazy(() => import('./Home.layout'))

export const Form = React.lazy(() => import('../components/containers/Forms/ApplicationForm.container'))

export const AdminLogin = React.lazy(() => import('../components/containers/Admin/Login.container'))

export const PROMOTIONALDATA = React.lazy(() => import('../components/containers/Admin/Promotions/PromotionalData.container'))

export const Login = React.lazy(() => import('../components/containers/Dashboard/Login.container'))

export const Dashboard = React.lazy(() => import('../components/containers/Dashboard/Dashboard.container'))

export const Feedback = React.lazy(() => import('../components/containers/Admin/CustomerPages/UserFeedback.container'))

export const VerificationPage = React.lazy(() => import('../components/containers/Home/ResponseForVerification.container'))

export const AmbassadorLogin = React.lazy(() => import('../components/containers/Ambassador/Login.container'))

export const AmbForm = React.lazy(() => import('../components/containers/Ambassador/Form.container'))

export const mobielFaq = React.lazy(() => import('../components/containers/Home/MobileAppFAQ.container'))

export const AmbDashboard = React.lazy(() => import('../components/containers/Ambassador/Dashboard.container'))

export const couponNew = React.lazy(() => import('../components/containers/Admin/AdminCoupons/CouponReporting.Container'))

export const calc = React.lazy(() => import('../components/containers/Calc/MainCalculator.container'))

export const calculator = React.lazy(() => import('../components/containers/Calc/Calculator.container'))

export const EmiCalc = React.lazy(() => import('../components/containers/Calc/EMICalculator.container'))

export const MerRegistration = React.lazy(() => import('../components/containers/Merchant/Registration.container'))

export const MerchantDashboard = React.lazy(() => import('../components/containers/Merchant/MerchantDashboard.container'))

export const MerchantLogin = React.lazy(() => import('../components/containers/Merchant/MerchantLogin'))

export const UPLOAD_DOCUMENT = React.lazy(() => import('../components/containers/Admin/UploadDocumentMail.container'))


export const CAMPAIGNTRACK = React.lazy(() => import('../components/containers/campaigntrack/AppDownload.Container'))

export const THIRDPARTYLOGIN = React.lazy(() => import('../components/containers/campaigntrack/Login.Container'))

export const THIRDPARTYREGISTRATIONS = React.lazy(() => import('../components/containers/campaigntrack/Registration.Container'))

export const APPLICANTREGISTRATION = React.lazy(() => import('../components/presentationals/Ambassador/FormApplicantDetail.component'))

export const WEBBLOG = React.lazy(() => import('../components/presentationals/WebRelatedPages/Blog.component'))

export const ENACHPAGE = React.lazy(() => import('../components/containers/Home/Enach.container'))
export const TECHPROCESS = React.lazy(() => import('../components/containers/Home/TechProcess.container'))

export const ESIGNPAGE = React.lazy(() => import('../components/containers/Home/NewEsign.container'))

// export const NBFCDASHBOARD = React.lazy(() => import('../components/containers/nbfc/Mainpage.container'))

// export const NBFCLOGIN = React.lazy(() => import('../components/containers/nbfc/NbfcLogin.container'))

export const UNSUBSCRIBE_PAGE = React.lazy(() => import('../components/containers/Home/Unsubscribe.container'))

export const analysis = React.lazy(() => import('../components/containers/Admin/Analysis/ProfileAnalysisNew.container'))

export const consent = React.lazy(() => import('../components/containers/facebookConsent/consentPage.container'))
export const Reference = React.lazy(() => import('../components/containers/Forms/UserReference.container'))
export const BadaBazaar = React.lazy(() => import('../components/containers/BadaBazaar/login.container'))
export const PoratlDashboard = React.lazy(() => import('../components/containers/BadaBazaar/MainPage.container'))
export const EsignMobile = React.lazy(() => import('../components/containers/Home/EsignIfram.container'))
export const EnachMobile = React.lazy(() => import('../components/containers/Home/EnachIframe.container'))

export const RazorpayPayment = React.lazy(() => import('../components/containers/Home/PaymentRazorPay.container'))



export const EnachCardPage = React.lazy(() => import('../components/containers/Admin/CardsUtility/CardEnach And Esign/Enach.container'))
export const EsignCardPage = React.lazy(() => import('../components/containers/Admin/CardsUtility/CardEnach And Esign/NewEsign.container'))
export const TEAGARDEN_LOGIN = React.lazy(() => import('../components/containers/TeaGarden/Login.container'))
export const TEAGARDEN_FORM = React.lazy(() => import('../components/containers/TeaGarden/NewForm.container'))
export const TEA_AMBASSADOR_PAGE = React.lazy(() => import('../components/containers/TeaGarden/AmbassadorTeaGarden/Login.container'))
export const TEA_AMBASSADOR_DASHBOARD_PAGE = React.lazy(() => import('../components/containers/TeaGarden/AmbassadorTeaGarden/Dashboard.container'))
export const SAVE_BANK_DETAILS_URL = React.lazy(() => import('../components/containers/Forms/saveBankDetailsIframe.container'))
export const ENACH_BILL_DESK = React.lazy(() => import('../components/containers/Home/EnachBillDesk.container'))
export const BILL_PAYMENT = React.lazy(() => import('../components/containers/Home/payWithBillDesk'))
export const BUISNESS_URL = React.lazy(() => import('../components/containers/Home/buisnessReport'))
export const DEMO = React.lazy(() => import('../components/containers/Admin/ApplicationLeads/demo.component'))
export const MOBILE_EMANDATE_BILLDESK = React.lazy(() => import('../components/containers/Home/billdeskIframe'))
export const MOBILE_PAY_BILLDESK = React.lazy(() => import('../components/containers/Home/billdeskpayMobile'))
export const FIDYPAY_MANDATE = React.lazy(() => import('../components/containers/Home/Fidypay'))
export const MARKETING_FORM = React.lazy(() => import('../components/containers/Marketing/registrationForm'))
export const ENACH_SIGNDESK = React.lazy(() => import('../components/containers/Home/EnachSigndesk'))
export const SEIGNDESK_REDIRECT_URL = React.lazy(() => import('../components/containers/Home/signdeskRedirectPage'))
export const KYC_FORM = React.lazy(() => import('../components/containers/Home/kycForm'))
export const KYC_MOBILE = React.lazy(() => import('../components/containers/Home/kycMobile'))
export const ENACH_SIGNDESK_MOBILE = React.lazy(() => import('../components/containers/Home/SigndeskIframe'))
export const KYC_DIGILOCKER = React.lazy(() => import('../components/containers/Home/digilKyc'))
export const DIGILOCKER_REDIRECT = React.lazy(() => import('../components/containers/Home/digilockerredirect'))
export const KFS_PAGE = React.lazy(() => import('../components/containers/Home/kfshtml'))


